// officeListSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: Record<string, any> = {
  data: [],
};

const officeListSlice = createSlice({
  name: "OfficeList",
  initialState,
  reducers: {
    reset: () => initialState,
    setOfficeList: (state, action: PayloadAction<Record<string, any>>) => {
      return { ...state, data: action?.payload || [] };
    },
  },
});

export const { setOfficeList, reset } = officeListSlice.actions;
export default officeListSlice.reducer;
