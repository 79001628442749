import { ThemeOptions } from '@mui/material';

import palette from './palette';
import spacing from './spacing';
import shadows from './shadows';
import breakpoints from './breakpoints';

// Extend ButtonPropsVariantOverrides to add custom variant 'containedRed'
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    containedRed: true;
  }
}
const mode = 'light';
// Define a light theme using ThemeOptions
const LIGHT_THEME: ThemeOptions = {
    palette: palette(mode),
    typography: {
        fontFamily: [
            'Inter',
            'sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        fontSize: 12 // Set the default font size
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
              @keyframes fade-in {
                from { opacity: 0; }
                to { opacity: 1; }
              }
            `
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        background: '#800000' // Change background color on hover
                    }
                }
            }, variants: [
                {
                    props: { variant: 'containedRed' }, // Define a custom variant
                    style: (theme) => ({
                        borderRadius: '10px', // Customize border radius
                        border: '6px solid #800000', // Add border
                        position: 'relative',
                        display: 'inline-block',
                        color: '#eee',
                        textTransform: 'none',
                        letterSpacing: '1px',
                        overflow: 'hidden',
                        boxShadow: '0 0 20px rgba(0, 0, 0, 1)',
                        fontFamily: 'verdana',
                        fontWeight: '600',
                        textDecoration: 'none',
                        background:
              "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.13)), radial-gradient(90% 7% at 50% 8%, rgba(255, 255, 255, 0.47) 25%, rgba(255, 255, 255, 0) 50%), #800000",
                        textShadow: '0px 0px 2px rgba(0, 0, 0, .5)',
                        transition: '0.2s',
                        '&:hover': {
                            background:
                "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.13)), radial-gradient(90% 7% at 50% 8%, rgba(255, 255, 255, 0.47) 25%, rgba(255, 255, 255, 0) 50%), #800000"
                        }
                    })
                }
            ]
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    [breakpoints.down("sm")]: {
                        fontSize: 10 // Adjust font size for small screens
                    },
                    [breakpoints.up("sm")]: {
                        fontSize: 12 // Default font size for medium screens
                    },
                    [breakpoints.up("md")]: {
                        fontSize: 14 // Adjust font size for large screens
                    }
                    // Add more font size adjustments for larger screens if needed
                }
            }
        }
    },
    shadows: shadows(mode),
    ...spacing,
    breakpoints: breakpoints,
    shape: {
        borderRadius: 6
    },
    mixins: {
        toolbar: {
            minHeight: 64
        }
    }
    // Define responsive typography within the components object
};

export default LIGHT_THEME;
