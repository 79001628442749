import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: Record<string, any> = {
  officeData: {},
  updatedOfficeData: {},
};

export const OfficeData = createSlice({
  name: "OfficeData",
  initialState,
  reducers: {
    reset: () => initialState,

    setOfficeData: (state, action: PayloadAction<Record<string, any>>) => {
      return { ...state, officeData: action?.payload ?? {} };
    },

    setSelectedOffice: (state, action: PayloadAction<Record<string, any>>) => {
      return {
        ...state,
        officeData: {
          ...state.officeData,
          Stewart: {
            ...state.officeData.Stewart,
            OfficeInfo: action.payload ?? "",
          },
        },
      };
    },
  },
});

export const { setOfficeData, reset, setSelectedOffice } = OfficeData.actions;
export default OfficeData.reducer;
