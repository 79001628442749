import { stringsConstantsProps } from "interfaces/stringsConstantstype";

export const stringsConstants: stringsConstantsProps = {
    headerButton2: "Help",
    headerButton3: "Close",
    headerTypoLoan: "Loan Number:",
    HederTypoWelcome: "Welcome",
    orderTracLablesSet: [
        "Order Placed",
        "Title Delivered",
        "Curative Cleared",
        "Settlement Statement Delivered",
        "Closing Complete",
        "Loan Funded",
        "Final Policy"
    ],
    orderTrac: "Milestone Tracker",
    actions: "Actions",
    obtainFee: "Obtain Fee Quotes",
    placeOrder: "Place Order",
    viewData: "View Data Fields",
    cancelOrder: "Cancel Order",
    payoffsdetails: "Payoff Details",
    importantNotesLabel: "Important Notes",
    importantNotes:
    "This section will contain static messages based on the order status of this file ",
    orderDetaillabel: "Order Details",
    orderDate: "Order Date",
    escrowFileNo: "Escrow File Number:",
    orderDataComparison: "Order Data Comparison:",
    selectOffice: "Select Office",
    centralized: "Centralized",
    localOffice: "Local Office",
    locateOffice: "Locate Office",
    borrowerslabel: "Borrowers",
    propertyInformationLabel: "Property Information",
    communications: "Communications",
    showAllComms: "Show All Communications",
    addMessage: "Add Message",
    addDocument: "Add Document",
    loanInformationlabel: "Loan Information",
    documents: "Documents",
    loanType: "Loan Type:",
    loanPurpose: "Loan Purpose:",
    amortization: "Amortization Type:",
    loanAmt: "Loan Amount:",
    purchasedPrice: "Purchase price:",
    estCosting: "Estimated Closing:",
    address: "Address:",
    county: "County:",
    propertyType: "Property Type:",
    borrowerLabel: "Borrower:",
    coBorrowerlabel: "CoBorrower:",
    findOffices: "Find Office(s)",
    cancel: "Cancel",
    distanceMiles: "Distance (miles)",
    locateAnOffice: "Locate an Office",
    browse: "Browse"
};
export const EncompassConstants: Record<string, string> = {
    CompanyName: "Stewart Title Company",
    EFolderDocumentID: "EFolderDocumentID",
    DocumentAttachmentID: "DocumentAttachmentID",
    Purchase: "Purchase",
    NoCashOutRefinance: "NoCash-Out Refinance",
    CashOutRefinance: "Cash-Out Refinance",
    Construction: "ConstructionOnly",
    ConstructionToPermanent: "ConstructionToPermanent",
    PayoffAccountTypeMortgage: "MortgageLoan",
    PayoffAccountTypeHELOC: "HELOC"
};

export const Messages: Record<string, string> = {
    AcceptedLoanPurpose:
    "The loan purpose must be a Purchase, Cash-Out Refinance, No Cash-Out Refinance, or Construction.",
    OfficeLocationRequired: "Office Location is required.",
    ValidSelection: "Please select appropriate option to proceed with order."
};

export const LenderCTSContract: Record<string, number> = {
    NoCTSContract: 0,
    CTSPurchaseOnly: 1,
    CTSRefinanceOnly: 2,
    CTSPurchaseAndRefinance: 3
} as const;

export const ApiPathConsts: Record<string, string> = {
    orderStatus: "orderStatus",
    placeOrder: "placeOrder",
    getLenderSettings: "getLenderSettings",
    validateAddress: "validateAddress",
    validateZipcode: "validateZipcode",
    sendNotes: 'sendNotes'
};
export const HelocOptions = {
    Off: 0,
    InsuredSelected: 1,
    UninsuredSelected: 2
} as const;

export const StewartConstants: Record<string, string | number> = {
    StewartCompanyID: "STW987654321",
    ClientReferenceNumber: "ClientReferenceNumber",
    DocumentList: "DocumentList",
    DocumentReferenceNumberPrefix: "Stewart",
    SaveLoginInformation: "SaveLoginInformation",
    LoginUserName: "LoginUserName",
    LoginPassword: "LoginPassword",
    IV: "IV",
    IV_Value: "hOrSLFl3Wgqf2xB+58tqow==",
    StatusList: "StatusList",
    StewartContextID: "StewartContextID",
    IsCancelled: "IsCancelled",
    Success: "success",
    TestUser: "testuser01",
    SelectedAgentID: "SelectedAgentID",
    SelectedOfficeName: "SelectedOfficeName",
    SelectedOfficeAddress1: "SelectedOfficeAddress1",
    SelectedOfficeAddress2: "SelectedOfficeAddress2",
    SelectedOfficeCity: "SelectedOfficeAddressCity",
    SelectedOfficeState: "SelectedOfficeAddressState",
    SelectedOfficePostalCode: "SelectedOfficeAddressPostalCode",
    SelectedOfficePhone: "SelectedOfficePhone",
    SelectedOfficeEmailAdress: "SelectedOfficeEmailAddress",
    IsOfficeAddressSaved: "IsOfficeAddressSaved",
    LenderContractID: "LenderContractID",
    IsCTS: "IsCTS",
    MessageList: "MessageList",
    PayoffList: "PayoffList",
    StewartFileNumber: "StewartFileNumber",
    FNCFileNumber: "FNCFileNumber",
    StewartRateCalculator_LenderRole: 1,
    StewartRateCalculator_LoanEstimate: 3,
    RecordingFeesLineNumber: "1202",
    TransferTaxesLineNumber: "1203",
    CityCountyTransferTaxesLineNumber: "1204",
    StateTransferTaxesLineNumber: "1205",
    AdditionalTransferTaxesLineNumber: "1206",
    AdditionalTransferTaxesSecondLineNumber: "1207",
    AdditionalTransferTaxesThirdLineNumber: "1208",
    AdditionalTransferTaxesFourthLineNumber: "1209",
    RecordingFeeDeedDescription: "Deed - Recording Fees",
    RecordingFeeMortgageDescription: "Mortgage - Recording Fees",
    RecordingFeeReleaseDescription: "Release - Recording Fees",
    CityTransferTaxesDeedDescription: "Deed - City Transfer Tax",
    CityTransferTaxesMortgageDescription: "Mortgage - City Transfer Tax",
    CountyTransferTaxesDeedDescription: "Deed - County Transfer Tax",
    CountyTransferTaxesMortgageDescription: "Mortgage - County Transfer Tax",
    StateTransferTaxesDeedDescription: "Deed - State Transfer Tax",
    StateTransferTaxesMortgageDescription: "Mortgage - State Transfer Tax",
    FLAdditionalTransferTaxesMortgageDescription: "Mortgage - Intangible Tax",
    MNAdditionalTransferTaxesMortgageDescription: "Mortgage - Conservation Fund",
    MNAdditionalTransferTaxesDeedDescription: "Deed - Conservation Fund",
    MDAdditionalTransferTaxesDeedDescription: "Deed Recordation Tax - State",
    MDAdditionalTransferTaxesMortgageDescription:
    "Mortgage Recordation Tax - State",
    VAAdditionalTransferTaxesGrantorStateDescription: "Grantor - State",
    VAAdditionalTransferTaxesGrantorCountyDescription: "Grantor - County",
    VAAdditionalTransferTaxesGrantorCityDescription: "Grantor - City",
    VAAdditionalTransferTaxesGranteeStateDescription: "Grantee - State",
    VAAdditionalTransferTaxesGranteeCountyDescription: "Grantee - County",
    VAAdditionalTransferTaxesGranteeCityDescription: "Grantee - City",
    SettlementFeeDescription: "Settlement Fee",
    ClosingFeeDescription: "Closing Fee",
    EscrowFeeDescription: "Escrow Fee",
    ClosingProtectionLetterDescription: "Closing Protection Letter",
    CPLDescription: "CPL",
    InsuredClosingLetterDescription: "Insured Closing Letter",
    CAAffordableHousingFeeDescription:
    "Title S B2 Affordable Housing Recording Fee",
    TotalSettlementServiceProviderListItems: 10,
    PendingPlaceOrderStatus: "Pending (Place Order)",
    PendingFileNumberStatus: "Pending (Escrow File Number)",
    SuccessStatus: "Success",
    OrderConfirmedStatus: "Order Confirmed",
    Yes: "Y",
    PayoffTypeDemand: "Demand",
    PayoffTypeSubordination: "Subordination",
    CTSOfficeHeading: "Stewart Centralized Title Services",
    FNC_ORDER_NOTE_SUBJECT: "FNC FILE NUMBER:"
} as const;

export const requiredFieldIds: string[] = [
    "LoanNumber",
    "LoanAmount",
    "LoanPurpose",
    "SalePrice",
    "PropertyStreet",
    "PropertyCity",
    "PropertyState",
    "PropertyZipCode",
    "LoanPurpose",
    "BorrowerFirstName",
    "BorrowerLastName",
    "BorrowerAddress",
    "BorrowerCity",
    "BorrowerState",
    "BorrowerZipCode"
];

export const coBorrowerFieldIds: string[] = [
    "CoBorrowerFirstName",
    "CoBorrowerMiddleName",
    "CoBorrowerLastName",
    "CoBorrowerSuffix",
    "CoBorrowerPhoneNumber",
    "CoBorrowerWorkNumber",
    "CoBorrowerCellNumber",
    "CoBorrowerEmailAddress",
    "CoBorrowerMaritalStatus",
    "CoBorrowerAddress",
    "CoBorrowerCity",
    "CoBorrowerState",
    "CoBorrowerZipCode"
];
export const RichTexts: Record<string, string> = {
    orderprocessing:
    "Thank you for your request. We will begin processing your order shortly."
} as const;
export const RadioConsts: Record<string, any> = {
    isHelocRadios: ["Insured", "UnInsured"],
    isConstruction: ["Purchase", "Refinance"],
    isReverseOrder: ["Reverse"],
    isSigningOnlyOrder: ["Signing Only"]
} as const;

export const documentBodyTableCols: string[] = [
    "",
    "Document Name",
    "Date",
    "Status",
    "Click to View",
    "Download"
];

export const API_URL = process.env.REACT_APP_ECP_API_URL as string;
