/* eslint-disable quote-props */
// ** MUI Imports
import { Theme } from '@mui/material/styles';

const Table = (theme: Theme) => {
    return {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    // verticalAlign: 'baseline'
                    // boxShadow: theme.shadows[0],
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-head': {
                        fontSize: 12,
                        fontWeight: 600,
                        letterSpacing: '0.13px',
                        borderBottom: `2px solid ${theme.palette.common.black}`

                    }
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-body': {
                        letterSpacing: '0.25px',
                        color: theme.palette.text.secondary

                    }
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {

                    '& .MuiTableCell-head:first-of-type, & .MuiTableCell-root:first-of-type ': {
                        // paddingLeft: theme.spacing(5)
                    },
                    '& .MuiTableCell-head:last-of-type, & .MuiTableCell-root:last-of-type': {
                        // paddingRight: theme.spacing(5)
                    }
                    // verticalAlign: 'baseline'

                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    '& .MuiButton-root': {
                        textTransform: 'uppercase',
                        color: theme.palette.text.secondary

                    },
                    minWidth: theme.spacing(22),
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: theme.spacing(40)

                }
                // stickyHeader: {
                //     backgroundColor: theme.palette.customColors.main,
                // },
            }
        }
    };
};

export default Table;
