// store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "redux/features/authSlice";
import OfficeData from "redux/features/contractsSlice";
import lenderSetting from "redux/features/lenderSlice";
import loanReducer from "redux/features/loanSlice";
import officeListReducer from "redux/features/officeListSlice";

const reducer = {
    auth: authReducer,
    loan: loanReducer,
    officeData: OfficeData,
    officeList: officeListReducer,
    lender: lenderSetting
};

const store = configureStore({
    reducer
});

export default store;
