// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Button = (theme: Theme) => {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: 14,
                    borderRadius: 5,
                    lineHeight: 1.71,
                    letterSpacing: '1px',
                    textTransform: "none",
                    padding: `${theme.spacing(1.975, 3)}`,

                    '&:hover': {
                        background: '#800000' // Change background color on hover
                    },
                    '&:disabled': {
                        background: theme.palette.grey.A100,
                        color: theme.palette.common.black

                    }
                },
                contained: {
                    boxShadow: theme.shadows[3],
                    padding: `${theme.spacing(1.875, 4.5)}`,
                    background: '#800000',
                    // Media query for screens between 901px and 1200px
                    '@media (max-width: 1200px) and (min-width: 901px)': {
                        padding: `${theme.spacing(2, 1)}`
                    },

                    // Media query for screens between 601px and 900px
                    '@media (max-width: 900px) and (min-width: 601px)': {
                        padding: `${theme.spacing(1.5)}`,
                        fontSize: 12
                    },

                    // Media query for screens up to 600px
                    '@media (max-width: 600px)': {
                        padding: `${theme.spacing(1.2)}`,
                        fontSize: 12

                    }

                },
                outlined: {
                    padding: `${theme.spacing(1.625, 5.25)}`
                },
                containedRed: {
                    borderRadius: `${theme.spacing(3.4)}`, // Customize border radius
                    border: `${theme.spacing(2)} solid ${theme.palette.customColors.main}`, // Add border
                    position: 'relative',
                    display: 'inline-block',
                    color: '#eee',
                    textTransform: 'none',
                    letterSpacing: '1px',
                    overflow: 'hidden',
                    boxShadow: `0 0 20px rgba(0, 0, 0, 1)`,
                    textDecoration: 'none',
                    background:
                        "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.13)), radial-gradient(90% 7% at 50% 8%, rgba(255, 255, 255, 0.47) 25%, rgba(255, 255, 255, 0) 50%), #800000",
                    textShadow: '0px 0px 2px rgba(0, 0, 0, .5)',
                    transition: '0.2s',
                    '&:hover': {
                        background:
                            "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.13)), radial-gradient(90% 7% at 50% 8%, rgba(255, 255, 255, 0.47) 25%, rgba(255, 255, 255, 0) 50%), #800000"
                    },
                    '&:disabled': {
                        background: theme.palette.grey.A100,
                        color: theme.palette.common.black,
                        border: `${theme.spacing(2)} solid ${theme.palette.grey.A100}` // Add border
                    }
                },
                sizeSmall: {
                    padding: `${theme.spacing(1, 2.25)}`,
                    '&.MuiButton-contained': {
                        padding: `${theme.spacing(1, 3.5)}`
                    },
                    '&.MuiButton-outlined': {
                        padding: `${theme.spacing(0.75, 3.25)}`
                    }
                },
                sizeLarge: {
                    padding: `${theme.spacing(2.125, 5.5)}`,
                    '&.MuiButton-contained': {
                        padding: `${theme.spacing(2.125, 6.5)}`
                    },
                    '&.MuiButton-outlined': {
                        padding: `${theme.spacing(1.875, 6.25)}`
                    }
                }
            }

        }

    };
};

export default Button;
