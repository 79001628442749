import { CircularProgress, Box } from "@mui/material";

const FullScreenLoader = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100vh"
    sx={{ background: "gray" }}
  >
    <CircularProgress />
  </Box>
);

export default FullScreenLoader;
