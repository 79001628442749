import { stringsConstants } from "constants/strings";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: Record<string, any> = {
    data: {},
    transactionObject: {},
    richTexts: stringsConstants.importantNotes,
    createdAt: "",
    isCancelled: false,
    options: {
    //add props as required
        IsCTS: true,
        clientFileNumber: "",
        isOrderPlaced: false,
        status: {},
        clientID: "",
        StewartContextID: "",
        Notes: [],
        Documents: [],
        rwDocs: [],
        localOfficeAddress: {}
    },
    epcResources: [],
    userSelectedResources: [],
    lenderSettings: {},
    selectedLoanType: ""
};

export const loadnData: any = createSlice({
    name: "loanData",
    initialState,
    reducers: {
        reset: () => initialState,

        setLoanData: (state, action: PayloadAction<Record<string, any>>) => {
            return { ...state, data: action?.payload ?? {} };
        },
        setOptions: (state, action: PayloadAction<Record<string, any>>) => {
            const newNotes = action.payload.Note;

            if (action.payload.hasOwnProperty("Note")) {
                if (Array.isArray(newNotes)) {
                    state.options.Notes.push(...newNotes);
                } else {
                    state.options.Notes.push(newNotes);
                }
                Reflect.deleteProperty(action.payload, "Note");
            }
            Object.assign(state.options, action.payload);
        },

        setTransactionObject: (
            state,
            action: PayloadAction<Record<string, any>>
        ) => {
            return { ...state, transactionObject: action?.payload ?? {} };
        },
        setTransactionId: (state, action: PayloadAction<Record<string, any>>) => {
            return {
                ...state,
                transactionObject: {
                    ...state.transactionObject,
                    transactionId: action.payload ?? ""
                }
            };
        },
        setTransactionResources: (
            state,
            action: PayloadAction<Record<string, any>>
        ) => {
            return {
                ...state,
                epcResources: action.payload?.epcResources,
                options: action?.payload.options ?? {},
                isOrderPlaced: true,
                createdAt: action.payload.createdAt
            };
        },

        setNotes: (state, action: PayloadAction<Record<string, any>>) => {
            return { ...state, richTexts: action?.payload ?? "" };
        },
        setComm: (state, action) => {
            return {
                ...state,
                options: {
                    ...state.options,
                    Notes: [...(state.options.Notes || []), { ...action.payload }]
                }
            };
        },

        setRWDocs: (state, action: PayloadAction<{}[]>) => {
            return {
                ...state,
                options: {
                    ...state.options,
                    rwDocs: [...(state.options.rwDocs || []), ...action.payload]
                }
            };
        },

        setDocuments: (
            state,
            action: PayloadAction<
        {
          DOCUMENT: {
            DocumentContent: string;
            DocumentDateTime: string;
            DocumentDescription: string;
            DocumentIdentifier: number;
            DocumentName: string;
            DocumentType: string;
            EncodeType: string;
            FileName: string;
            FileType: string;
          };
        }[]
      >
        ) => {
            return {
                ...state,
                options: {
                    ...state.options,
                    Documents: [...(state.options.Documents || []), ...action.payload]
                }
            };
        },

        setUserSelectedResources: (
            state,
            action: PayloadAction<Record<string, any>>
        ) => {
            return {
                ...state,
                userSelectedResources: action?.payload || []
            };
        },

        setLoanType: (state, action: PayloadAction<Record<string, any>>) => {
            return { ...state, selectedLoanType: action?.payload ?? {} };
        },

        setLocalOfficeAddress: (
            state,
            action: PayloadAction<Record<string, any>>
        ) => {
            return {
                ...state,
                options: {
                    ...state.options,
                    IsCTS: false,
                    localOfficeAddress: action.payload || {}
                }
            };
        }
    }
});

export const {
    setLoanData,
    reset,
    setOptions,
    setTransactionObject,
    setTransactionId,
    setTransactionResources,
    setNotes,
    setComm,
    setDocuments,
    setRWDocs,
    setUserSelectedResources,
    setLoanType,
    setLocalOfficeAddress
} = loadnData.actions;
export default loadnData.reducer;
