import type React from 'react';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import AppThemeProvider from 'themes/AppThemeProvider';
import store from 'redux/store';
import { Provider } from 'react-redux';


const ErrorFallback = () => {
    return (
        <div
            className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
            role="alert"
        >
            <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
            <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        Refresh
            </Button>
        </div>
    );
};

type AppProviderProps = {
  children: React.ReactNode;
};
export const AppProvider = ({ children }: AppProviderProps) => {
    return (
        <Suspense
            fallback={
                <div className="flex items-center justify-center w-screen h-screen">
                    <CircularProgress size="xl" />
                </div>
            }
        >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Provider store={store}>
                    <AppThemeProvider>
                        <Router>{children}</Router>
                    </AppThemeProvider>
                </Provider>
            </ErrorBoundary>
        </Suspense>
    );
};
