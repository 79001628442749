import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import host from "@elliemae/em-ssf-guest";

import { AppProvider } from "providers";

import reportWebVitals from "./reportWebVitals";
import FullScreenLoader from "componnets/Loader";

import "./index.css";

const App = React.lazy(() => import("./App"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

host.connect();

root.render(
  <React.StrictMode>
    <AppProvider>
      <Suspense fallback={<FullScreenLoader />}>
        <App />
      </Suspense>
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
