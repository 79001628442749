import { PayloadAction, createSlice } from "@reduxjs/toolkit";


const initialState :Record<string, any> = {
    lenderSettings: {
        Setting: 'khjk'
    },
    loanTypeOptions: {
        loanType: '',
        loanTypeRadioOptions: [],
        loanTypeCheck: '',
        isHelocTypeSelected: false,
        isConstruction: false,
        EnableReverseOrders: false
    }
};

export const lenderSetting:any = createSlice({
    name: "lender",
    initialState,
    reducers: {
        reset: () => initialState,
        setLenderSettings: (state, action: PayloadAction<Record<string, any>>) => {
            return { ...state, lenderSettings: action?.payload ?? {} };
        },
        setLoanTypeOptions: (state, action: PayloadAction<Record<string, any>>) => {
            return { ...state, loanTypeOptions: action?.payload ?? {} };
        },
        resetLoanTypeOptions: (state) => {
            return {
                ...state,
                loanTypeOptions: {
                    ...state.loanTypeOptions,
                    loanTypeRadioOptions: [],
                    loanTypeCheck: '',
                    isHelocTypeSelected: false,
                    isConstruction: false,
                    EnableReverseOrders: false
                }
            };
        }

    }
});

export const {
    setLenderSettings,
    reset,
    resetLoanTypeOptions,
    setLoanTypeOptions
} = lenderSetting.actions;
export default lenderSetting.reducer;
